<script setup>
const { path } = useRoute()
const isShowMenu = ref(false)

const handleShowMenu = () => {
  isShowMenu.value = !isShowMenu.value
}
const close = () => {
  isShowMenu.value = false
}

</script>

<template>
  <transition name="slide-right">
    <ModalMenu v-if="isShowMenu" @close="close"/>
  </transition>
  <nav class="flex p-y-4 p-x-8 bg-white border-top-t3-1 position-fixed bottom-0 left-0 w-100-p box-border z-100">
    <NuxtLink
      class="text-caption2 flex flex-direction-column justify-center items-center w-25-p h-60 text-gray6"
      to="/"
      @click.native="close"
    >
      <IconHome
        class="block item-align--center m-bottom-4 text-gray4"
        width="24"
        height="24"
      />
      Home
    </NuxtLink>
    <NuxtLink
      class="text-caption2 flex flex-direction-column justify-center items-center w-25-p h-60 text-gray6"
      to="/college/find"
      @click.native="close"
    >
      <IconCollege
        class="block item-align--center m-bottom-4 text-gray4"
        width="24"
        height="24"
      />
      College
    </NuxtLink>
    <NuxtLink
      class="text-caption2 flex flex-direction-column justify-center items-center w-25-p h-60 text-gray6"
      to="/mypond"
      @click.native="close"
    >
      <IconMyPond
        class="block item-align--center m-bottom-4 text-gray4"
        width="24"
        height="24"
      />
      My Pond
    </NuxtLink>
    <NuxtLink
      class="text-caption2 flex flex-direction-column justify-center items-center w-25-p h-60 text-gray6"
      to="/roadmap"
      @click.native="close"
    >
      <IconRoadmap
        class="block item-align--center m-bottom-4 text-gray4"
        width="24"
        height="24"
      />
      Roadmap
    </NuxtLink>
<!--    <NuxtLink-->
<!--      class="text-caption2 flex flex-direction-column justify-center items-center w-25-p h-60 text-gray6"-->
<!--    >-->
<!--      <IconMatching-->
<!--        class="block item-align&#45;&#45;center m-bottom-4 text-gray4"-->
<!--        width="24"-->
<!--        height="24"-->
<!--      />-->
<!--      Matching-->
<!--    </NuxtLink>-->
    <button
      class="text-caption2 flex flex-direction-column justify-center items-center w-25-p h-60 text-gray6"
      @click="handleShowMenu"
      :class="isShowMenu ? 'text-primary5 text-700' : 'text-gray6'"
    >
      <IconAll
        class="flex-none block item-align--center m-bottom-4"
        :class="isShowMenu ? 'text-primary5' : 'text-gray4'"
        width="24"
        height="24"
      />
      Menu
    </button>
  </nav>
</template>

<style scoped lang="scss">
.router-link-active {
  color: $primary5;
  font-weight: 700;
  .icon {
    color: inherit;
  }
}
</style>
