<script setup>
import { useModal } from 'vue-final-modal'
import { useMemberSessionStore } from 'share/stores/memberSessionStore'
import { storeToRefs } from 'pinia'
import useScrollLock from '@share/composables/useScrollLock'
import TermsAndConditions from '~/components/modal/auth/TermsAndConditions.vue'
import useSubscriptionService from 'share/services/payment/useSubscriptionService'

import Notification from '~/components/layout/Notification.vue'
const { isLoggedIn, memberInfo } = storeToRefs(useMemberSessionStore())

const emit = defineEmits(['handleShowMenu'])
const { enableScrollLock, disableScrollLock } = useScrollLock(false)
const { logout } = useMemberSessionStore()
const { fetchSubscriptionInfo, myPlanString, myPlanButtonString, subscriptionInfo } = useSubscriptionService()

const logoutAndClose = () => {
  logout()
  close()
}
const close = () => {
  emit('close')
}

const handleShowTermsModal = () => {
  const { open, destroy } = useModal({
    component: TermsAndConditions,
    attrs: {
      onConfirm: () => destroy(),
    },
  })
  open()
}

onMounted(() => {
  enableScrollLock()
  if (isLoggedIn.value) {
    fetchSubscriptionInfo()
  }
})

onUnmounted(() => {
  disableScrollLock()
})

const gotoViewingPass = () => {
  sessionStorage.setItem('viewingPass', 'true')

  close()
  navigateTo('/purchase/subscription')
}

const mentoringHistoryUrlCheck = (currentUrl) => {
  // console.log('currentUrl')
  // console.log(currentUrl)

  // Check if the current URL starts with the specified pattern
  if (currentUrl.startsWith('/mentoring/info/booking/')) {
    // Check if memberInfo is available and return mentoringHistory
    console.log('true')
    return true
  }
  // Return an empty array if the URL does not match or memberInfo is not available
  return false
}
</script>

<template>
  <nav class="overflow-y-scroll z-10 position-fixed top-0 right-0 box-border w-100-p"
    style="min-height: calc(100vh - 69px); max-height: calc(100vh - 69px)">
    <div class="bg-white position-relative z-10 p-bottom-40 p-top-24 p-x-24 h-100-p box-border h-100-p menu-content">
      <div class="flex items-center justify-between h-40">
        <div class="flex items-center">
          <button class="logo" @click="navigateTo('/')"><span class="blind">The Pond</span></button>
          <mark class="m-left-8 radius-200 border-a-primary3-1 p-x-6 h-16 text-caption2 text-primary4 inline-block">
            Beta
          </mark>
        </div>

        <Notification />
      </div>
      <div v-if="isLoggedIn" class="p-a-12 border-bottom-t2-1 m-top-16">
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <div class="w-32 h-32 radius-200">
              <img :src="memberInfo.imageUrl ? memberInfo.imageUrl : '/images/avatar.svg'" alt=""
                class="fit-cover w-100-p h-100-p radius-200" />
            </div>
            <div class="m-left-8">
              <strong class="text-body2-b">{{ memberInfo.name }}</strong>
              <div class="text-caption2 text-gray5">{{ memberInfo.memberType }} / {{ memberInfo.email }}</div>
            </div>
          </div>
          <IconSetting width="24" height="24" color="#747474" @click="navigateTo('/mypond/settings')" />
        </div>
        <div class="flex items-center justify-between m-top-16">
          <p class="text-caption1 text-gray6">
            My plan
            <strong class="text-body2 text-primary5 m-top-4 text-500 block">
              {{ myPlanString }}
            </strong>
          </p>
          <UiButton class="light-primary small" @click="navigateTo('/purchase/subscription')">
            {{ myPlanButtonString }}
          </UiButton>
        </div>
        <div class="flex justify-between w-100-p m-bottom-4 m-top-12">
          <p class="text-caption1 text-gray5">Free Viewing Passes</p>
          <p class="text-caption1-b text-gray6">
            {{ subscriptionInfo.freeTotalPassCount - subscriptionInfo.freeUsedPassCount }}
            / {{ subscriptionInfo.freeTotalPassCount }}
          </p>
        </div>
        <div class="flex justify-between w-100-p m-bottom-24">
          <p class="text-caption1 text-gray5">Purchased Viewing Passes</p>
          <p class="text-caption1-b text-gray6">
            {{ subscriptionInfo.purchasedTotalPassCount - subscriptionInfo.purchasedUsedPassCount }}
            / {{ subscriptionInfo.purchasedTotalPassCount }}
          </p>
        </div>
        <div class="flex justify-end">
          <div>
            <UiTextButton class="w-100-p small" style="white-space: pre" @click="gotoViewingPass">
              Viewing Pass Recharge
            </UiTextButton>
          </div>
        </div>
      </div>
      <div v-else class="m-top-20 border-bottom-t2-1 bg-white p-y-20">
        <strong class="text-body1-b block text-center m-bottom-20">Get Started for free!</strong>
        <div class="flex justify-between items-center">
          <UiButton @click="navigateTo('/auth/sign-in')" class="small light-primary" style="width: calc(50% - 6px)">
            Sign in
          </UiButton>
          <UiButton @click="navigateTo('/auth/sign-up')" class="small" style="width: calc(50% - 6px)">
            Sign up free!
          </UiButton>
        </div>
      </div>
      <div class="body-scroll-target flex flex-direction-column">
        <div>
          <ul class="m-y-16">
            <li>
              <NuxtLink to="/" @click.native="close"
                class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12"
                active-class="bg-primary5 text-white text-700">
                <img src="@share/assets/images/menu/home.svg" alt="" class="w-24 h-24 m-right-8" />
                Home
              </NuxtLink>
              <div v-if="!isLoggedIn">
            <li v-if="menuStatus === true" style="display: flex;
                    padding: 8px 0px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 10px;
                    align-self: stretch;">
              <UiSeparator class="unfold-separator"></UiSeparator>
            </li>
            <li v-else style="display: flex;
          padding: 8px 0px;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          align-self: stretch;">

              <UiSeparator class="fold-separator"></UiSeparator>
            </li>
        </div>
        </li>
        <li v-if="isLoggedIn">
          <NuxtLink to="/mypond" @click.native="close"
            class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12 m-right-8"
            active-class="bg-primary5 text-white text-700">
            <img src="@share/assets/images/menu/my.svg" alt="" class="w-24 h-24 m-right-8" />
            My Pond
          </NuxtLink>
          <div v-if="menuStatus === true" style="display: flex;
                    padding: 8px 0px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 10px;
                    align-self: stretch;">
            <UiSeparator class="unfold-separator"></UiSeparator>
          </div>
          <div v-else style="display: flex;
          padding: 8px 0px;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          align-self: stretch;">

            <UiSeparator class="fold-separator"></UiSeparator>
          </div>
        </li>
        <li v-if="isLoggedIn">
          <NuxtLink to="/roadmap" @click.native="close"
            class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12 m-right-8"
            active-class="bg-primary5 text-white text-700">
            <img src="@share/assets/images/menu/roadmap.svg" alt="" class="w-24 h-24 m-right-8" />
            Roadmap
          </NuxtLink>
        </li>
        <!-- <li>
          <a href="/mentoring/find" @click.native="close"
            class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12"
            active-class="bg-primary5 text-white text-700" :class="mentoringHistoryUrlCheck($router.currentRoute.value.path)
                ? 'bg-primary5 text-white text-700'
                : 'no-class'
              ">
            <img src="@share/assets/images/menu/find.svg" alt="" class="w-24 h-24 m-right-8" />
            Mentoring Program
          </a>
        </li> -->
        <li v-if="isLoggedIn">
          <!-- <NuxtLink to="/mentoring/info" @click.native="close"
            class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12"
            active-class="bg-primary5 text-white text-700"
            :class="mentoringHistoryUrlCheck($router.currentRoute.value.path) ? 'unsetActive' : 'no-class'">
            <img src="@share/assets/images/menu/matching.svg" alt="" class="w-24 h-24 m-right-8" />
            Mentoring History
          </NuxtLink> -->
        </li>
        <!-- <div v-if="menuStatus === true" style="display: flex;
                    padding: 8px 0px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 10px;
                    align-self: stretch;">
          <UiSeparator class="unfold-separator"></UiSeparator>
        </div>
        <div v-else style="display: flex;
          padding: 8px 0px;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          align-self: stretch;">

          <UiSeparator class="fold-separator"></UiSeparator>
        </div> -->
        <li>
          <NuxtLink to="/college/find" @click.native="close"
            class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12"
            active-class="bg-primary5 text-white text-700">
            <img src="@share/assets/images/menu/college.svg" alt="" class="w-24 h-24 m-right-8" />
            College Finder
          </NuxtLink>
        </li>
        <li>
          <NuxtLink to="/extracurriculars" @click.native="close"
            class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12"
            active-class="bg-primary5 text-white text-700">
            <img src="@share/assets/images/menu/activity.svg" alt="" class="w-24 h-24 m-right-8" />
            Activity Search
          </NuxtLink>
        </li>
        <li>
          <NuxtLink to="/career/search" class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12"
            active-class="bg-primary5 text-white text-700">
            <img src="@share/assets/images/menu/career.svg" alt="" class="w-24 h-24 m-right-8" />
            <span class="hide-on-folded">Career Finder</span>
          </NuxtLink>
          <div v-if="menuStatus === true" style="display: flex;
                  padding: 8px 0px;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;">

            <UiSeparator class="unfold-separator"></UiSeparator>
          </div>
          <div v-else style="display: flex;
              padding: 8px 0px;
              flex-direction: column;
              align-items: center;
              gap: 10px;
              align-self: stretch;">

            <UiSeparator class="fold-separator"></UiSeparator>
          </div>
        </li>
        <li>
          <NuxtLink to="https://www.blog.diveintothepond.com/" target="_blank"
            class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12"
            active-class="bg-primary5 text-white text-700">
            <img src="@share/assets/images/menu/blog.svg" alt="" class="w-24 h-24 m-right-8" />
            Blog
          </NuxtLink>
        </li>
        </ul>
        <!-- <UiLabel class="payment">Coming soon</UiLabel>
          <ul class="m-bottom-32 m-top-4">
            <li>
              <div class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12">
                <img src="@share/assets/images/menu/courses.svg" alt="" class="w-24 h-24 m-right-8" />
                Courses
              </div>
            </li>
            <li>
              <div class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12">
                <img src="@share/assets/images/menu/feedback.svg" alt="" class="w-24 h-24 m-right-8" />
                Essay Feedback
              </div>
            </li>
          </ul> -->
      </div>
      <template v-if="isLoggedIn">
        <div class="p-x-12 p-y-10 m-bottom-37">
          <button class="reset-button text-body2 text-gray6 flex items-center" @click="logoutAndClose">
            <IconSignOut width="24" height="24" class="m-right-8" color="#000" />
            Sign Out
          </button>
        </div>
        <div class="flex justify-center p-top-8 m-top-37">
          <button class="reset-button text-underline text-black text-caption2 m-right-17" @click="handleShowTermsModal">
            Terms
          </button>
          <a href="https://www.iubenda.com/privacy-policy/18448319"
            class="text-underline text-black text-caption2 m-right-17" target="_blank">
            Privacy
          </a>
          <a href="https://www.iubenda.com/privacy-policy/18448319/cookie-policy"
            class="text-underline text-black text-caption2" target="_blank">
            Cookie
          </a>
        </div>
        <p class="m-y-16 text-center">
          <a href="mailto:customerservice@letitu.io" class="text-underline text-black text-caption2" target="_blank">
            Contact us
          </a>
        </p>
        <p class="text-caption2 text-gray4 text-center">©Letitu Corp. All rights reserved</p>
      </template>
      <div v-else>
        <div class="flex justify-center p-top-8 m-top-37">
          <button class="reset-button text-underline text-black text-caption2 m-right-17" @click="handleShowTermsModal">
            Terms
          </button>
          <a href="https://www.iubenda.com/privacy-policy/18448319"
            class="text-underline text-black text-caption2 m-right-17" target="_blank">
            Privacy
          </a>
          <a href="https://www.iubenda.com/privacy-policy/18448319/cookie-policy"
            class="text-underline text-black text-caption2" target="_blank">
            Cookie
          </a>
        </div>
        <p class="m-y-16 text-center">
          <a href="mailto:customerservice@letitu.io" class="text-underline text-black text-caption2" target="_blank">
            Contact us
          </a>
        </p>
        <p class="text-caption2 text-gray4 text-center">©Letitu Corp. All rights reserved</p>
      </div>
    </div>
    </div>
    <div class="h-100-p position-fixed top-0 left-0 right-0 bottom-0 w-100-p bg-t4" @click="close"></div>
  </nav>
</template>

<style scoped lang="scss">
.logo {
  width: 110px;
  height: 25px;
  background-repeat: no-repeat;
  background-image: url('@share/assets/images/logo.svg');
  background-size: contain;
}

.menu-content {
  min-height: calc(100vh - 69px);
  width: 88.88vw;
  margin-left: auto;
}

@supports (-webkit-touch-callout: none) {
  .menu-content {
    padding-bottom: 160px;
  }
}
</style>
